.App {
  text-align: center;
  background-color: #EDF1F7;
  height: 100vh;
  font-family: Nunito Sans;
}

.HomePage{
  background: #FBFBFB;
}

.App section{
  padding: 4rem 10%;
  background: #EDF1F7;
}

.App-content {
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/*Columnes formulari*/
.CmpForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  justify-content: center;
  padding: 20px;
  padding-right: 100px;
  width: 35%;
}

/*Global del formulari*/
.CmpFormGlobal{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
  position: relative;
  background-color: rgba(5,170,0,.68);
  border-radius: 25px;
}

.CmpFormGlobal img{
  position: absolute;
  right: 50px;
  top: -80px;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.backgroundEspecial{
  clip-path: polygon(0 0, 100% 0, 100% 25%, 0 100%);
  background-color: #EDF1F7;
  position: absolute;
  width: 100%;
  height: 30vh;
  top: 0;
}

@media (max-width: 900px){

  /*Pagina principal INI*/
  .App-content{
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .CmpForm{
    flex-direction: column;
  }

  .CmpFormGlobal form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CmpButton{
    margin-bottom: 10px;
  }

  .CmpVideo iframe{
    width: 80%;
    max-width: 560px;
  }

  .CmpFormGlobal img {
    right: 10px;
  }
  /*Pagina principal FIN*/
}
