.CmpDetalleTarifa{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.CmpDetalleTarifa th{
    padding: 8px;
    text-align: center;
    background-color: rgba(5, 170, 0, 0.68);
    color: white;
    border: 1px solid #ddd;
}

.CmpDetalleTarifa td{
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.CmpDetalleTarifa tr:nth-child(even){background-color: #f2f2f2;}

.CmpDetalleTarifa tr:hover {background-color: #ddd;}