.CmpQandA{
    padding: 0px 30px 30px 30px;
    width: 70%;
    margin: auto;
    background-color: rgba(5,170,0,.68);
    border-radius: 15px;
}

.CmpQandA h2{
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: 600;
    color: rgba(51, 21, 214, 0.7);
    font-family: Poppins SemiBold;
}

.CmpQandA h3 button{
    font-size: 20px;
    font-weight: 600;
}

.accordion-body{
    text-align: left;
}

@media (max-width: 1120px){
    .CmpQandA{
        width: 95%;
    }
}