.RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 98px;
    height: 25px;
    margin-top: 0px;
    background-color: #d66515d6;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }

.CmpTarifa-butons div div{
    margin-right: 4px;
}

.CmpTarifaDestacado{
  border: 3px solid rgba(5, 170, 0, 0.68);
}

.CmpTarifa-butons{
  display: flex;
  padding: 0px 15px;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  width: 240px;
}

.CmpTarifa-butons div:first-child{
  margin-bottom: 5px;
}

.CmpTarifa-columna{
  padding: 0px 15px;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid #bbb;
  width: 250px;
}

.CmpTarifa-total{
  font-weight: 600;
}

.CmpTarifa.true{
  border: 2px solid rgba(5,170,0,.68);
  background-color: rgba(5,170,0,.10);
}

.CmpTarifa-Image{
}


@media (max-width: 900px){
  .CmpTarifa{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  .CmpTarifa-columna{
      border-right: 0px;
  }
  .CmpTarifa-total{
    font-weight: 600;
    font-size: 30px;
  }
}