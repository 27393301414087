.CmpInfo{
    display: flex;
    flex-direction: column;
    color: rgba(51, 21, 214, 0.7);
    justify-content: center;
    font-family: Poppins SemiBold;
    min-height: 500px;
    width: 60%;
    padding: 20px;
    align-items: flex-start;
}

.CmpInfo p{
    font-size: 15px;
    text-align: left;
    margin-bottom: 3px;
}

.CmpInfo h2{
    padding-top: 20px;
    font-size: 15px;
    text-align: left;
    color: white;
}

.CmpInfo h1{
    font-size: 50px;
    text-align: right;
}

@media (max-width: 1120px){
    .CmpInfo{
        min-height: 0px;
        margin-top: 20px;
        width: 100%;
        align-items: center;
    }
    .CmpInfo h1{
        font-size: 40px;
    }
}