.CmpButton{
    font-family: Nunito Sans;
    background-color: rgba(51, 21, 214, 0.8);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .CmpButton:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
  }
  .CmpButton:active{
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
  }

  .RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    background-color: #d66515d6;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }