.CmpMenu{
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #EDF1F7;
}

.CmpMenu a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.CmpMenu h1{
    color: #5EA253;
    font-size: 20px;
}

.CmpMenu img{
    width: 50px;
}