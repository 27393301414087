.CmpFilter{
    display: flex;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    min-width: 250px;
    margin-bottom: 20px;
    display: table;
    background-color: white;
    margin-right: 10px;
}

.CmpFilterFiltros{
    display: flex;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
    height: 35px;
}

.CmpFilterFiltros p{
    font-size: 20px;
}

/*CmpCheckbox INI*/
.CmpFilterCheckBox{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}

.CmpFilterCheckBox h3{
    font-size: 20px;
}
.CmpFilterCheckBox label{
    font-size: 15px;
}
/*CmpCheckBox FIN*/

.CmpFilterSelect{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}
.CmpFilterSelect h3{
    font-size: 20px;
}
.CmpFilterSelect select{
    font-size: 15px;
}

.CmpOrderBy{
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3px;
    position: absolute;
    right: 0;
    top: 0;
}