body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: local('Poppins SemiBold'), url(/static/media/Poppins-SemiBold.6f1520d1.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(/static/media/NunitoSans-Regular.4dac7051.ttf) format('truetype');
  font-weight: 400;
}
.App {
  text-align: center;
  background-color: #EDF1F7;
  height: 100vh;
  font-family: Nunito Sans;
}

.HomePage{
  background: #FBFBFB;
}

.App section{
  padding: 4rem 10%;
  background: #EDF1F7;
}

.App-content {
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/*Columnes formulari*/
.CmpForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  justify-content: center;
  padding: 20px;
  padding-right: 100px;
  width: 35%;
}

/*Global del formulari*/
.CmpFormGlobal{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
  position: relative;
  background-color: rgba(5,170,0,.68);
  border-radius: 25px;
}

.CmpFormGlobal img{
  position: absolute;
  right: 50px;
  top: -80px;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@keyframes mover {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}

.backgroundEspecial{
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 25%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 25%, 0 100%);
  background-color: #EDF1F7;
  position: absolute;
  width: 100%;
  height: 30vh;
  top: 0;
}

@media (max-width: 900px){

  /*Pagina principal INI*/
  .App-content{
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .CmpForm{
    flex-direction: column;
  }

  .CmpFormGlobal form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CmpButton{
    margin-bottom: 10px;
  }

  .CmpVideo iframe{
    width: 80%;
    max-width: 560px;
  }

  .CmpFormGlobal img {
    right: 10px;
  }
  /*Pagina principal FIN*/
}

.CmpButton{
    font-family: Nunito Sans;
    background-color: rgba(51, 21, 214, 0.8);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .CmpButton:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    -webkit-transform: translate(-0.05em, -0.05em);
            transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
  }
  .CmpButton:active{
    -webkit-transform: translate(0.05em, 0.05em);
            transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
  }

  .RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    background-color: #d66515d6;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }
/* Input formulari */

.CmpInput input {
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
    padding: 1rem;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: text;
    height: 15px;
    display: flex;
    margin-bottom: 15px;
    background: transparent;
    color: black;
    background-color: white;
   }

   .CmpInput input::-webkit-input-placeholder{
    color: black;
   }

   .CmpInput input::placeholder{
    color: black;
   }
   
   .CmpInput input:focus {
    outline: none;
    border: 1px solid rgb(27, 0, 227);
   }
  
   .CmpInput label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
   }
  
   .CmpInputError input{
     border-color: red;
   }
 /* Select formulari */

 .CmpSelect label{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin-bottom: 3px;
  }
  
  .CmpSelect select{
    padding: 0;
    height: 35px;
    font-size: 1rem;
    width: 10em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-bottom: 15px;
    background-color: white;
    color: black;
    box-shadow: 0 0 3px #82828280;
    border: 1px solid white;
  }
  
  .CmpSelect select:focus{
    outline-color: #9ED996;
    border-color: #9ED996;
  }

  .CmpSelect option{
    /*background-color: rgba(5, 170, 0, 0.68);*/
    background-color: white;
    color: black;
  }
.accordion-header{
    color: white;
    font-size: 12px;
    cursor: pointer;
}


.CmpButtonForm{
    font-family: Nunito Sans;
    background-color: rgba(51, 21, 214, 0.8);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CmpButtonForm:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    -webkit-transform: translate(-0.05em, -0.05em);
            transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
}
.CmpButtonForm:active{
    -webkit-transform: translate(0.05em, 0.05em);
            transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

.RellenarAutoForm{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    background-color: #d66515d6;
}
.RellenarAutoForm:hover{
    background-color: #d66515fe;
    transition: 0.2s;
}

.buttonsForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpFormPetit{
    background-color: #046b00e0;
    padding: 40px;
    border-radius: 10px;
    transition: 0.4s;
}

.CmpFormPetit:hover{
    background-color: #058100e0;
    transition: 0.4s;
}

.CmpFormColumna{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adsense{
    width: 100%;
}

@-webkit-keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

@keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

/*.CamposAdicionales{
    -webkit-animation: new 0.5s;
    animation: new 0.5s;
}
*/
@media (max-width: 1120px){
    .CmpFormGlobal{
        background-color: rgba(5,170,0,.68);
        border-radius: 25px;
        flex-direction: column;
    }

    .CmpForm{
        padding: 0px;
        min-height: 0px;
        padding-bottom: 20px;
    }
}
.CmpInfo{
    display: flex;
    flex-direction: column;
    color: rgba(51, 21, 214, 0.7);
    justify-content: center;
    font-family: Poppins SemiBold;
    min-height: 500px;
    width: 60%;
    padding: 20px;
    align-items: flex-start;
}

.CmpInfo p{
    font-size: 15px;
    text-align: left;
    margin-bottom: 3px;
}

.CmpInfo h2{
    padding-top: 20px;
    font-size: 15px;
    text-align: left;
    color: white;
}

.CmpInfo h1{
    font-size: 50px;
    text-align: right;
}

@media (max-width: 1120px){
    .CmpInfo{
        min-height: 0px;
        margin-top: 20px;
        width: 100%;
        align-items: center;
    }
    .CmpInfo h1{
        font-size: 40px;
    }
}
.CmpGraficaIndexada{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.CmpGraficaIndexada h2{
    color: #5EA253;
}
.CmpMenu{
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #EDF1F7;
}

.CmpMenu a{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.CmpMenu h1{
    color: #5EA253;
    font-size: 20px;
}

.CmpMenu img{
    width: 50px;
}
.CmpQandA{
    padding: 0px 30px 30px 30px;
    width: 70%;
    margin: auto;
    background-color: rgba(5,170,0,.68);
    border-radius: 15px;
}

.CmpQandA h2{
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: 600;
    color: rgba(51, 21, 214, 0.7);
    font-family: Poppins SemiBold;
}

.CmpQandA h3 button{
    font-size: 20px;
    font-weight: 600;
}

.accordion-body{
    text-align: left;
}

@media (max-width: 1120px){
    .CmpQandA{
        width: 95%;
    }
}
.CmpVideo{
    color: rgba(51, 21, 214, 0.7);
    padding-top: 15px;
    position: relative;
    z-index: 1;
    font-family: Poppins SemiBold;
}

.CmpVideo h2{
    font-weight: 500;
}
.CmpDetalleTarifa{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.CmpDetalleTarifa th{
    padding: 8px;
    text-align: center;
    background-color: rgba(5, 170, 0, 0.68);
    color: white;
    border: 1px solid #ddd;
}

.CmpDetalleTarifa td{
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.CmpDetalleTarifa tr:nth-child(even){background-color: #f2f2f2;}

.CmpDetalleTarifa tr:hover {background-color: #ddd;}
.CmpTarifa-Image{
    width: 70px;
}
  
.CmpTarifa{
    display: flex;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    display: table;
    height: 100%;
    background-color: white;
    width: 100%;
}

.CmpTarifa-columna:nth-child(4){
    min-width: 146px;
}

.CmpTarifa-title{
    font-size: 12px;
}

.CmpTarifa-precio{
    font-size: 20px;
}

.CmpTarifa-Compania{
    font-size: 15px;
}

.CmpTarifa-detalle{
    font-size: 15px;
    margin-top: 5px;
    color: rgb(100, 100, 100);
    cursor: pointer;
}

.CmpTarifas{
    max-width: 900px;
    width: 100%;
    position: relative;
}

.CmpTarifas button{
    display: flex;
    position: absolute;
}

.CmpOffCanvas{
    width: 280px;
}

.RellenarAuto{
    font-weight: 300;
    font-size: 15px;
    width: 98px;
    height: 25px;
    margin-top: 0px;
    background-color: #d66515d6;
  }
  .RellenarAuto:hover{
    background-color: #d66515fe;
    transition: 0.2s;
  }

.CmpTarifa-butons div div{
    margin-right: 4px;
}

.CmpTarifaDestacado{
  border: 3px solid rgba(5, 170, 0, 0.68);
}

.CmpTarifa-butons{
  display: flex;
  padding: 0px 15px;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  width: 240px;
}

.CmpTarifa-butons div:first-child{
  margin-bottom: 5px;
}

.CmpTarifa-columna{
  padding: 0px 15px;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid #bbb;
  width: 250px;
}

.CmpTarifa-total{
  font-weight: 600;
}

.CmpTarifa.true{
  border: 2px solid rgba(5,170,0,.68);
  background-color: rgba(5,170,0,.10);
}

.CmpTarifa-Image{
}


@media (max-width: 900px){
  .CmpTarifa{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  .CmpTarifa-columna{
      border-right: 0px;
  }
  .CmpTarifa-total{
    font-weight: 600;
    font-size: 30px;
  }
}
.CmpFilter{
    display: flex;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    min-width: 250px;
    margin-bottom: 20px;
    display: table;
    background-color: white;
    margin-right: 10px;
}

.CmpFilterFiltros{
    display: flex;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
    height: 35px;
}

.CmpFilterFiltros p{
    font-size: 20px;
}

/*CmpCheckbox INI*/
.CmpFilterCheckBox{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}

.CmpFilterCheckBox h3{
    font-size: 20px;
}
.CmpFilterCheckBox label{
    font-size: 15px;
}
/*CmpCheckBox FIN*/

.CmpFilterSelect{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #bbb;
    margin-bottom: 10px;
}
.CmpFilterSelect h3{
    font-size: 20px;
}
.CmpFilterSelect select{
    font-size: 15px;
}

.CmpOrderBy{
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3px;
    position: absolute;
    right: 0;
    top: 0;
}
.CmpNotFound{
    background-color: rgba(5,170,0,.68);
    border-radius: 25px;
    color: white;
    padding: 50px;
    margin-top: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpNotFound img{
    position: absolute;
    top: 80px;
    right: 100px;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
  }

.CmpNotFound .CmpButton{
    margin-top: 30px;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
}
