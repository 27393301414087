.CmpVideo{
    color: rgba(51, 21, 214, 0.7);
    padding-top: 15px;
    position: relative;
    z-index: 1;
    font-family: Poppins SemiBold;
}

.CmpVideo h2{
    font-weight: 500;
}