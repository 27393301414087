.CmpTarifa-Image{
    width: 70px;
}
  
.CmpTarifa{
    display: flex;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    display: table;
    height: 100%;
    background-color: white;
    width: 100%;
}

.CmpTarifa-columna:nth-child(4){
    min-width: 146px;
}

.CmpTarifa-title{
    font-size: 12px;
}

.CmpTarifa-precio{
    font-size: 20px;
}

.CmpTarifa-Compania{
    font-size: 15px;
}

.CmpTarifa-detalle{
    font-size: 15px;
    margin-top: 5px;
    color: rgb(100, 100, 100);
    cursor: pointer;
}

.CmpTarifas{
    max-width: 900px;
    width: 100%;
    position: relative;
}

.CmpTarifas button{
    display: flex;
    position: absolute;
}

.CmpOffCanvas{
    width: 280px;
}
