.accordion-header{
    color: white;
    font-size: 12px;
    cursor: pointer;
}


.CmpButtonForm{
    font-family: Nunito Sans;
    background-color: rgba(51, 21, 214, 0.8);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 200px;
    height: 40px;
    float: right;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CmpButtonForm:hover{
    background-color: #3215d6;
    color: white;
    transition: 0.2s;
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em rgba(0,0,0,0.4);
}
.CmpButtonForm:active{
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

.RellenarAutoForm{
    font-weight: 300;
    font-size: 15px;
    width: 130px;
    height: 30px;
    margin-top: 10px;
    background-color: #d66515d6;
}
.RellenarAutoForm:hover{
    background-color: #d66515fe;
    transition: 0.2s;
}

.buttonsForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CmpFormPetit{
    background-color: #046b00e0;
    padding: 40px;
    border-radius: 10px;
    transition: 0.4s;
}

.CmpFormPetit:hover{
    background-color: #058100e0;
    transition: 0.4s;
}

.CmpFormColumna{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adsense{
    width: 100%;
}

@keyframes new {
    0% { opacity: 0%; }
    100% { opacity: 100%; }
  }

/*.CamposAdicionales{
    -webkit-animation: new 0.5s;
    animation: new 0.5s;
}
*/
@media (max-width: 1120px){
    .CmpFormGlobal{
        background-color: rgba(5,170,0,.68);
        border-radius: 25px;
        flex-direction: column;
    }

    .CmpForm{
        padding: 0px;
        min-height: 0px;
        padding-bottom: 20px;
    }
}